import React from "react";
import "./Pricing.scss";
import knegSolo from "../assets/img/kneg-solo.svg";
import knegMini from "../assets/img/kneg-mini.svg";
import knegMid from "../assets/img/kneg-mellan.svg";
import knegStor from "../assets/img/kneg-stor.svg";
import knegEnt from "../assets/img/kneg-enterprise.svg";

export function Pricing() {
  return (
    <div className="Pricing block-wrap">
      <div className="container padding-hero">
        <div className="grid">
          <div className="col col-8">
            <h1>Priser</h1>
            <p>
              Kneg köps som en prenumeration. I priset ingår det kompletta
              systemet med all funktionalitet. Precis som systemet så gör vi
              prissättningen enkel och tydlig. Ingen bindningstid och ingen
              startavgift eller andra dolda kostnader.
            </p>
            <p>
              I prenumerationen ingår alltid de senaste funktionerna med fria
              uppdateringar.
            </p>
          </div>
        </div>
      </div>
      <div className="block-wrap pricelist padding-l">
        <div className="container">
          <div className="grid gap-40">
            <div className="card col col-3">
              <p>
                <img className="price-icon" src={knegSolo} alt="Kneg Solo" />
                Kneg Solo
              </p>
              <p>
                <b>1</b> anställd
              </p>
              <p>
                <h2 className="price-point">199</h2> Kr/mån
              </p>
              <a href="#" className="btn black">
                Kom igång
              </a>
            </div>
            <div className="card col col-3">
              <p>
                <img className="price-icon" src={knegMini} alt="Kneg Mini" />
                Kneg Mini
              </p>
              <p>
                Upp till <b>5</b> anställda
              </p>
              <p>
                <h2 className="price-point">179</h2> Kr/mån <br /> per användare
              </p>
              <a href="#" className="btn black">
                Kom igång
              </a>
            </div>
            <div className="card col col-3">
              <p>
                <img className="price-icon" src={knegMid} alt="Kneg Mellan" />
                Kneg Mellan
              </p>
              <p>
                Upp till <b>10</b> anställda
              </p>
              <p>
                <h2 className="price-point">159</h2> Kr/mån <br /> per användare
              </p>
              <a href="#" className="btn black">
                Kom igång
              </a>
            </div>
            <div className="card col col-3">
              <p>
                <img className="price-icon" src={knegStor} alt="Kneg Stor" />
                Kneg Stor
              </p>
              <p>
                Upp till <b>20</b> anställda
              </p>
              <p>
                <h2 className="price-point">149</h2> Kr/mån <br /> per användare
              </p>
              <a href="#" className="btn black">
                Kom igång
              </a>
            </div>
          </div>
        </div>
        <div className="container enterprise">
          <img className="ent-icon" src={knegEnt} alt="Kneg Företag" />
          <div className="enterprise-txt">
            <h6>
              <span>Kneg Företag</span> <br /> Mer än 20 anställda
            </h6>
            <p>
              Är du ett större företag som vill ansluta dig till Kneg? <br />
              Kontakta oss så hjälper vi dig skräddarsy din lösning.
            </p>
          </div>
          <a href="#" className="btn special">
            Kontakta oss
          </a>
        </div>
      </div>
    </div>
  );
}
