import React from "react";
import "./Features.scss";
import taskPhone from "../assets/img/task-phone.png";
import happyCustomer from "../assets/img/happy-customer.png";
import collab from "../assets/img/collab.png";
import ready from "../assets/img/ready.png";
import acceptOffer from "../assets/img/accept-offer.png";
import sendOffer from "../assets/img/send-offer.png";
import hearts from "../assets/img/hearts.png";
import taskListIcon from "../assets/img/tasklist.svg";
import checkBoxIcon from "../assets/img/checkbox.svg";
import notesIcon from "../assets/img/notes.svg";
import imagesIcon from "../assets/img/images.svg";
import { CtaDownload } from "../components/CtaDownload";
import { KnowMore } from "../components/KnowMore";

export function Features() {
  return (
    <div className="Features">
      <div className="container padding-hero">
        <div className="grid gap-40">
          <div className="col col-8">
            <h1>Funktioner</h1>
            <p>
              Här kan du se en överblick över den funktionalitet som appen
              erbjuder och se hur den kan göra just ditt arbetsflöde bättre.
            </p>
          </div>
        </div>
      </div>

      <div className="block-wrap tasks">
        <div className="container">
          <div className="grid gap-40 v-align">
            <div className="col col-8">
              <div className="grid gap-40 padding-m">
                <div className="col col-12">
                  <h3>Uppdrag & Uppgifter</h3>
                  <p>
                    Få en helhetsbild över vilka uppgifter du och dina anställda
                    behöver göra. I uppdragslistan kan du se vilka uppdrag som
                    är redo att påbörjas, hur många uppgifter som är avklarade
                    och vilka uppdrag som är slutförda.
                  </p>
                </div>
                <div className="col col-6 h-align-resp">
                  <img src={taskListIcon} alt="Uppdrag" />
                  <div>
                    <h4>Uppdrag</h4>
                    <p>
                      Få en överblick över alla dina uppdrag och se status för
                      hur uppdragen fortlöper.
                    </p>
                  </div>
                </div>
                <div className="col col-6 h-align-resp">
                  <img src={checkBoxIcon} alt="Uppgifter" />
                  <div>
                    <h4>Uppgifter</h4>
                    <p>
                      Du och dina arbetskamrater ser tillsammans vilka uppgifter
                      som behöver utföras och se i realtid vilka som blir
                      avklarade.
                    </p>
                  </div>
                </div>
                <div className="col col-6 h-align-resp">
                  <img src={notesIcon} alt="Anteckningar" />
                  <div>
                    <h4>Anteckningar</h4>
                    <p>
                      Lägg till anteckningar för ytterligare information kring
                      ditt uppdrag för att lättare slippa missförstånd.
                    </p>
                  </div>
                </div>
                <div className="col col-6 h-align-resp">
                  <img src={imagesIcon} alt="Bilder" />
                  <div>
                    <h4>Bilder</h4>
                    <p>
                      Med hjälp av bilder kan du, kunden och dina medarbetare få
                      en bättre bild över uppgiften som behöver göras.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-4 task-phone">
              <img src={taskPhone} alt="Kneg - Uppdragslista" />
            </div>
          </div>
        </div>
      </div>

      <div className="block-wrap offer">
        <div className="container padding-l">
          <div className="grid gap-40 padding-s">
            <div className="col col-8">
              <h2>Offert</h2>
              <p>
                Skicka kundofferter direkt från appen till dina kunder, som de
                kan öppna direkt i en webbläsare. Få sedan notiser när offerten
                blivit godkänd och ditt arbete kan börja.
              </p>
            </div>
          </div>
          <div className="grid gap-40">
            <div className="col col-4">
              <img src={sendOffer} alt="Skicka offert" />
              <h4>Skicka offert</h4>
              <p>
                Med hjälp av appen kan du enkelt skicka en offert till kunden
                via e-post, sms eller länk.
              </p>
            </div>
            <div className="col col-4">
              <img src={acceptOffer} alt="Godkänn offert" />
              <h4>Godkänn offert</h4>
              <p>
                Kunden kan enkelt öppna offerten i sin telefon eller i valfri
                webbläsare för att läsa och godkänna eller neka offerten.
              </p>
            </div>
            <div className="col col-4">
              <img src={ready} alt="Notifiering" />
              <h4>Redo att starta</h4>
              <p>
                När kunden godkänt eller nekat din offert får du direkt en notis
                i appen om uppdragets status.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="block-wrap easy">
        <div className="container padding-l">
          <div className="grid gap-40 padding-s">
            <div className="col col-8">
              <h3>Enkelt och säkert</h3>
              <p>
                Med tydligt specifierade uppdrag och uppgifter får du och kunden
                samma bild över vad som behöver göras vilket leder till mindre
                missförstånd och en bättre relation till din kund.
              </p>
            </div>
          </div>
          <div className="grid gap-40">
            <div className="col col-4 h-align-resp">
              <img src={collab} alt="Samarbete" />
              <div>
                <h4>Förenkla samarbete</h4>
                <p>
                  Arbeta tillsammans med dina kollegor och har samma bild över
                  uppdragets status och vad som behöver göras.
                </p>
              </div>
            </div>

            <div className="col col-4 h-align-resp">
              <img src={hearts} alt="Inga missförstånd" />
              <div>
                <h4>Inga missförstånd</h4>
                <p>
                  Med tydligt specifierade uppdrag och uppgifter blir risken för
                  missförstånd mindre mellan dig och dina kunder.
                </p>
              </div>
            </div>

            <div className="col col-4 h-align-resp">
              <img src={happyCustomer} alt="Nöjda kunder" />
              <div>
                <h4>Nöjdare kunder</h4>
                <p>
                  Genom tydligare arbetsbeskrivningar bidrar vi till ett bättre
                  samarbete där mindre missförstånd sker, vilket i sin tur ger
                  dig nöjdare kunder.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CtaDownload />
      <KnowMore />
    </div>
  );
}
