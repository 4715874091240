import React from "react";
import "./Home.scss";
import taskOverview from "../assets/img/task-overview.png";
import quickOffer from "../assets/img/quick-offer.png";
import customerChat from "../assets/img/kneg-improve-relation.png";
import { CtaDownload } from "../components/CtaDownload";
import { KnowMore } from "../components/KnowMore";
import heroPhone from "../assets/img/hero-splash.png";
import { Link } from "react-router-dom";

export function Home() {
  return (
    <div className="Home">
      <div className="hero">
        <div className="container padding-l">
          <div className="grid v-align">
            <div className="col col-6 hero-image">
              <img src={heroPhone} alt="Kneg app" />
            </div>
            <div className="col col-6">
              <h1>Din bästa kompis i servicearbetet</h1>
              <p>
                En att göra-app för serviceuppdrag. Håll koll vad som ska göras
                med hjälp av ett strömlinjeformat system som förenklar för både
                dig och din kund.
              </p>
              <Link to="/funktioner" className="btn black">
                Läs mer
              </Link>
              <Link to="/kontakt" className="btn outline">
                Kontakta oss
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="block-wrap short-desc padding-l">
        <div className="container">
          <div className="grid gap-40">
            <div className="col col-3"></div>
            <div className="col col-6 center-text">
              <h2>Effektivisera och öka kvaliteten på dina tjänster</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <p>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-wide block-wrap"></div>

      <div className="block-wrap padding-l">
        <div className="container">
          <div className="grid v-align gap-40">
            <div className="col col-6 center-text">
              <img src={taskOverview} alt="Kneg - Uppdragsöversikt" />
            </div>
            <div className="col col-6">
              <h2>Få en bättre översikt över dina uppdrag & uppgifter</h2>
              <p>
                Få en helhetsbild över vilka uppgifter du och dina anställda
                behöver göra. I uppdragslistan kan du se vilka uppdrag som är
                redo att påbörjas, hur många uppgifter som är avklarade och
                vilka uppdrag som är slutförda.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="block-wrap send-offer">
        <div className="container">
          <div className="grid v-align gap-40">
            <div className="col col-6 padding-l">
              <h2>Skicka offert snabbt och smidigt</h2>
              <p>
                Skicka kundofferter direkt från appen till dina kunder med bara
                några klick. Kunden kan sedan öppna offerten direkt i en
                webbläsare. Få sedan notiser när offerten blivit godkänd och
                ditt arbete kan börja.
              </p>
            </div>
            <div className="col col-6 center-text overflow-mobile">
              <img src={quickOffer} alt="Kneg - Skicka offert snabbt" />
            </div>
          </div>
        </div>
      </div>

      <div className="block-wrap padding-l">
        <div className="container">
          <div className="grid v-align gap-40">
            <div className="col col-6 center-text">
              <img src={customerChat} alt="Kneg - Kundrelationer" />
            </div>
            <div className="col col-6">
              <h2>Förbättra din relation till kunden</h2>
              <p>
                Med tydligt specifierade uppdrag och uppgifter får du och kunden
                samma bild över vad som behöver göras vilket leder till mindre
                missförstånd och en bättre relation till din kund.
              </p>
            </div>
          </div>
        </div>
      </div>

      <CtaDownload />
      <KnowMore />
    </div>
  );
}
