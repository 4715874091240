import React from "react";
import "../App.scss";
import "./Footer.scss";
import logo from "../assets/img/kneg-yellow.svg";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <div className="footer padding-l">
      <div className="container">
        <div className="grid gap-40">
          <div className="col col-12">
            <img className="logo" src={logo} alt="Kneg Logotyp" />
          </div>
          <div className="col col-3">
            <p>© Copyright Pure IT North 2022</p>
            <p>Utvecklad och underhållen av Pure IT North.</p>
            <p>
              Pure IT North AB
              <br />
              Västra Norrlandsgatan 11D, 3tr
              <br />
              90327 Umeå, SE
            </p>
          </div>
          <div className="col col-3"></div>
          <div className="col col-3">
            <ul>
              <li>
                <Link to="/funktioner">Funktioner</Link>
              </li>
              <li>
                <Link to="/priser">Priser</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </div>
          <div className="col col-3">
            <ul>
              <li>
                <a href="#">Allmänna villkor</a>
              </li>
              <li>
                <a href="#">Integritespolicy</a>
              </li>
              <li>
                <a href="#">Kakpolicy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
