import React, { useEffect, useState } from "react";
import "./MainNav.scss";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/kneg-black.svg";

/* export interface MainNavProps {
  title: string;
} */
export function MainNav(/* props: MainNavProps */) {
  const [isActive, setActive] = useState(false);
  const [offset, setOffset] = useState(0);
  const [menuOnTop, setMenuOnTop] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset < 100) {
        setMenuOnTop(true);
      } else {
        setMenuOnTop(false);
      }
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function toggleActive() {
    setActive(!isActive);
  }

  function closeActive() {
    setActive(false);
  }

  let activeStyle = {
    textDecoration: "underline",
  };

  return (
    <header className={menuOnTop ? "App-header top" : "App-header"}>
      <div className="container">
        <Link onClick={closeActive} className="logo" to="/">
          <img src={logo} alt="Kneg Logo" />
        </Link>
        <ul className={isActive ? "main-nav active" : "main-nav"}>
          <li>
            <NavLink
              onClick={closeActive}
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              to="/funktioner"
            >
              Funktioner
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeActive}
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              to="/priser"
            >
              Priser
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeActive}
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              to="/kontakt"
            >
              Kontakt
            </NavLink>
          </li>
          <span>
            <li>
              <a className="black btn" href="#">
                Ladda ner appen
              </a>
            </li>
            <li>
              <a className="outline btn" href="#">
                Logga in
              </a>
            </li>
          </span>
        </ul>
        <div
          className={
            isActive ? "active mobile-menu-toggle" : "mobile-menu-toggle"
          }
          onClick={toggleActive}
        >
          <div className="menu-bar"></div>
        </div>
      </div>
    </header>
  );
}
