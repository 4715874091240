import React from "react";
import { KnowMore } from "../components/KnowMore";
import coffee from "../assets/img/coffee.png";
import mail from "../assets/img/mail.png";
import phone from "../assets/img/phone.png";
import "./Contact.scss";

export function Contact() {
  return (
    <div className="Contact">
      <div className="container padding-hero">
        <div className="grid">
          <div className="col col-8">
            <h1>Kontakt</h1>
            <p>
              Gör som tusentals andra nöjda kunder börja med Kneg. En
              helhetslösning för dig och din verksamhet - smidigare än Kneg blir
              det inte!
            </p>
            <p>
              För mer information kan du kontakta oss via mejl eller telefon.
            </p>
          </div>
        </div>
      </div>
      <div className="block-wrap padding-s">
        <div className="container">
          <div className="grid gap-40 padding-s">
            <div className="col col-4">
              <div className="contact-image-frame">
                <img src={phone} alt="Telefon" />
              </div>
              <h4>Telefon</h4>
              <p>+46(0)90-340 01 20</p>
            </div>
            <div className="col col-4">
              <div className="contact-image-frame">
                <img src={mail} alt="E-post" />
              </div>
              <h4>E-post</h4>
              <p>kneg@pure.se</p>
            </div>
            <div className="col col-4">
              <div className="contact-image-frame">
                <img src={coffee} alt="Hitta hit" />
              </div>
              <h4>Hitta hit</h4>
              <p>
                Pure IT North AB
                <br />
                Västra Norrlandsgatan 11D, 3tr.
                <br />
                90327 Umeå, SE
              </p>
            </div>
          </div>
        </div>
      </div>
      <KnowMore />
    </div>
  );
}
