import React from "react";
import { Link } from "react-router-dom";
import "./404.scss";

export function NotFound() {
  return (
    <div className="NotFound">
      <div className="container padding-hero">
        <p>
          <b>404 - Page not found</b>
        </p>
        <h1>Hoppsan!</h1>
        <h3>Vi kunde inte hitta sidan du letade efter.</h3>
        <Link className="arrow-link" to="/">
          Gå tillbaka till förstasidan
        </Link>
      </div>
    </div>
  );
}
