import React from "react";
import "./KnowMore.scss";
import { Link } from "react-router-dom";

export function KnowMore() {
  return (
    <div className="KnowMore block-wrap padding-l">
      <div className="container">
        <div className="grid center-text">
          <div className="col col-3"></div>
          <div className="col col-6">
            <h2>Kom igång med Kneg</h2>
            <p>
              Vår service app går att anpassa till era behov och till er
              verksamhet. Vi har ett grundutförande som ni kan prova
              kostnadsfritt. Känner ni att Kneg är något för er så skräddarsyr
              vi en plan för er. Önskar ni anpassa eller saknar ni någon
              funktion så ordnar vi det.
            </p>
            <Link to="/priser" className="btn primary">
              Se prislista
            </Link>
            <a href="#" className="btn outline-white">
              Ladda ner appen
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
