import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainNav } from "./components/MainNav";
import { Footer } from "./components/Footer";
import { Home } from "./components/Home";
import { Features } from "./components/Features";
import { Pricing } from "./components/Pricing";
import { Contact } from "./components/Contact";
import { NotFound } from "./components/404";
import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <Router>
      <div className="App">
        <MainNav />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/funktioner" element={<Features />} />
            <Route path="/priser" element={<Pricing />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
