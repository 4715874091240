import React from "react";
import "./CtaDownload.scss";
import AssCleared from "../assets/img/ass-cleared.png";
import appStore from "../assets/img/appstore.png";
import googleStore from "../assets/img/googlestore.png";

export function CtaDownload() {
  return (
    <div className="CtaDownload block-wrap">
      <div className="container">
        <div className="grid v-align">
          <div className="col col-8 copy">
            <h2>Ladda ner våran app</h2>
            <p>
              Testa appen redan idag med begränsad funktionalitet. Skapa uppdrag
              och uppgifter och slutför dom.
            </p>
            <p>
              För att kunna skicka offerter behöver du bli en betalande kund, se
              våra prismodeller
            </p>
            <a href="#">
              <img src={appStore} alt="Download on Apple Store" />
            </a>
            <a href="#">
              <img src={googleStore} alt="Download on Google Play" />
            </a>
          </div>
          <div className="col col-4 center-text overflow-mobile">
            <img src={AssCleared} alt="Kneg - Ladda ner appen" />
          </div>
        </div>
      </div>
    </div>
  );
}
